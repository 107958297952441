<template>
    <div class="account">
      <h1>My Account</h1>
      <p>Manage your account settings here.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Account'
  }
  </script>
  
  <style scoped>
  .account {
    padding: 20px;
  }
  </style>